export const healthCheckupRouter = [
  //  员工体检
  // 体检机构管理
  {
    path: '/healthCheckup/mechanismMng',
    name: 'mechanismMng',
    component: () => import('../views/healthCheckup/mechanismMng')
  },
  // 体检套餐管理
  {
    path: '/healthCheckup/healthMealMng',
    name: 'healthMealMng',
    component: () => import('../views/healthCheckup/healthMealMng')
  },
  // 体检套餐--套餐详情
  {
    path: '/healthCheckup/healthMealDtl',
    name: 'healthMealDtl',
    component: () => import('../views/healthCheckup/healthMealDtl')
  },
  // 订单管理
  {
    path: '/healthCheckup/healthOrderMng',
    name: 'healthOrderMng',
    component: () => import('../views/healthCheckup/healthOrderMng')
  },
  // 订单管理--详情
  {
    path: '/healthCheckup/healthOrderDtl',
    name: 'healthOrderDtl',
    component: () => import('../views/healthCheckup/healthOrderDtl')
  },
  // 发放列表
  {
    path: '/healthCheckup/grantList',
    name: 'grantList',
    component: () => import('../views/healthCheckup/grantList')
  },
  // 预约列表
  {
    path: '/healthCheckup/reservationList',
    name: 'reservationList',
    component: () => import('../views/healthCheckup/reservationList')
  },
];